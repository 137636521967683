<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-material-card
      icon="mdi-clipboard-text"
      title="Swift Check-in Settings"
      class="px-5 py-3"
    >
      <v-card>
        <v-tabs
          v-model="tab"
          background-color="#FF3700"
          dark
        >
          <v-tab
            v-for="item in items"
            :key="item.tab"
          >
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="item in items"
            :key="item.tab"
          >
            <v-card flat>
              <v-card-text>
                <component :is="item.content" />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </base-material-card>
  </v-container>
</template>

<script>
  import QuestionnaireList from './questionnaire/QuestionnaireList';

  export default {
    name: 'SettingsHome',

    components: {
      'questionnaire-list': QuestionnaireList,
    },

    data: () => ({
      tab: null,
      items: [
        { tab: 'Questionnaire', content: QuestionnaireList },
      ],
    }),
  };
</script>
