<template>
  <div>
    <v-dialog
      v-model="showModal"
      max-width="500"
    >
      <v-form>
        <v-card>
          <v-card-title>
            Edit {{ name }}

            <v-spacer />

            <v-icon
              aria-label="Close"
              @click="showModal = false"
            >
              mdi-close
            </v-icon>
          </v-card-title>

          <v-card-text class="text-body-1 text-center">
            <v-container>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="name"
                    label="Question Name"
                    hint="Name is only for your internal reference."
                    outlined
                  />
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    v-model="type"
                    label="Type"
                    hint="Answer type - checkbox, normal form"
                    outlined
                    disabled
                  />
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="question"
                    label="Question"
                    hint="Question to your users."
                    outlined
                  />
                </v-col>

                <hr>

                <v-col
                  cols="12"
                  class="text-right"
                >
                  <v-btn
                    :disabled="maxAnswersReached"
                    color="#FF3700"
                    @click="addAnswer()"
                  >
                    Add Answer
                  </v-btn>
                </v-col>
              </v-row>
              <div
                v-for="(answer, index) in answers"
                :key="index"
              >
                <v-col cols="12">
                  <v-text-field
                    v-model="answers[index].answer"
                    :label="answerLabel(index)"
                    hint="Answer to the above to question."
                    outlined
                  >
                    <template v-slot:append-outer>
                      <v-icon
                        color="red darken-2"
                        @click="removeAnswer(index)"
                      >
                        mdi-minus-circle
                      </v-icon>
                    </template>
                  </v-text-field>
                </v-col>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="red darken-3"
              depressed
              default
              @click="cancel()"
            >
              Cancel
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#FF3700"
              depressed
              default
              @click="submit()"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
  import axios from 'src/app-axios';
  import constants from 'src/constants';

  export default {
    name: 'EditQuestion',
    props: {
      editinfo: {
        type: Object,
        required: true,
      },
    },
    data: () => ({
      id: null,
      name: null,
      question: null,
      type: 'checkbox',
      answers: [],
      showModal: true,
    }),

    computed: {
      maxAnswersReached () {
        return this.answers.length >= 3;
      },
    },

    watch: {
      showModal: function (newval, oldval) {
        if (newval === false) {
          this.$emit('closed');
        }
      },
    },

    created () {
      this.fillDetails();
    },

    methods: {
      cancel () {
        this.showModal = false;
      },

      fillDetails () {
        this.id = this.editinfo.id;
        this.name = this.editinfo.name;
        this.question = this.editinfo.question;
        this.answers = this.editinfo.answers;
      },

      submit () {
        const uri = 'api/swift-checkin/questionnaire/' + this.id;
        const payload = {
          name: this.name,
          question: this.question,
          answers: this.answers,
          type: this.type,
        };

        axios({
          url: uri,
          data: payload,
          method: 'PUT',
        }).then(response => {
          this.showModal = false;
          this.$emit('success');
        }).catch(() => {
          this.showModal = false;
          this.$store.dispatch('alert/onAlert', {
            message: 'Something went wrong in updating questionnaire.',
            type: constants.ALERT_TYPE_ERROR,
          });
        });
      },

      addAnswer () {
        this.answers.push({ answer: '' });
      },

      removeAnswer (index) {
        this.answers.splice(index, 1);
      },

      answerLabel (index) {
        return 'Answer ' + (index + 1);
      },
    },
  };
</script>
